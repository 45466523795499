import './Serviciu.css'

function Serviciu({image, label, text, inConstruction}) {
    return (
        <div className="serviciu-container">
            <div className="first-column">
                <img src={image} className="serviciu-image" alt="imagine-serviciu"/>
                <div className="serviciu-title text-md-2">{label}</div>
                {inConstruction && <div className='s-uc-container'>
                    <div className='s-uc-content text-md-2'>UNDER CONSTRUCTION</div>
                </div>}
            </div>
            <div className="serviciu-descriere text-md-2">{text}</div>
        </div>
    )
}
export default Serviciu;