import './Header.css'

function Header() {
    return (
        <div id="header-bar" className='text-sm-1 layer-up'>
            <div>MTGProxyShop</div>
            {/* <div>About</div>
            <div>Contact</div> */}
        </div>
    )
}
export default Header;