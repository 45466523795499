import './Servicii.css'
import Serviciu from './serviciu/Serviciu';
import imgServ1 from '../images/s1.jpg'
import imgServ2 from '../images/s2.jpg'
import imgServ3 from '../images/s3.jpg'
import imgServ4 from '../images/s4.jpg'
import imgServ5 from '../images/s5.jpg'

function Servicii() {

    // îâșță
    // cărți
    let servicii = [
        { nume: "Full deck", image: imgServ1, description: "Printare întreg Proxy deck pentru jocul Magic The Gathering pentru orice format, conținând orice număr de cărți proxy. Pentru mai mult de 50 de cărți comandate oferim o cutie de depozitare fără extra cost. Fiecare carte vine cu învelitoare de protecție" },
        { nume: "Orice carte", image: imgServ2, description: "Printare carduri proxy Magic The Gathering individuale. Fiecare carte vine cu învelitoare de protecție" },
        { nume: "Cărți Token", image: imgServ3, description: "Printare carduri proxy de tip Token. La comenzi mai mari de 50 de cărți vom adăuga gratuit, din partea noastră 5 extra token-uri"},
        { nume: "Pre-configurate", image: imgServ4, description: "Deck-uri proxy Magic The Gathering distractive, pregătite de noi. Sunt prezentate in sectiunea “Pre-configurate”. Momentan această secțiune este in construcție", inConstruction: true},
        { nume: "Spate personalizat", image: imgServ5, description: "Printare spate carduri proxy cu o imagine personalizată. Trimite imaginea in email-ul de comandă. Imaginea trebuie sa aibă rezoluția și formatul corect"}
    ]

    return (
        <div className="section-back layer-up">

            <div className='section-front with-margins'>
                <div className='space-sm'></div>
                <div className="section-title text-lg-2">Servicii oferite</div>

                <div className="servicii-container">
                    { servicii.map( (serviciu, index) => (
                        <Serviciu key={index} label={serviciu.nume} text={serviciu.description} image={serviciu.image} inConstruction={serviciu.inConstruction}/>
                    ))}
                </div>
            </div>
            <div className='space-sm'></div>
        </div>
    )
}
export default Servicii;