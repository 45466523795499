import './Dece.css'
import DeceCard from './dece-card/DeCeCard';
import { useLayoutEffect, useState } from 'react';

function Dece() {
    const [showFullDece, setShowFullDece] = useState(false);

    useLayoutEffect(() => {
        if (window.innerWidth > 800) {
            setShowFullDece(true);
        }
    }, [showFullDece]);

    // Îîâșță 
    // cărți
    let items = [
        { title: 'Costuri reduse', content: 'Comandă un Proxy deck cu orice carte la un preț fix'},
        { title: 'Testează carte', content: 'Încearcă cărți la alegere pentru a testa în deck-ul tău'},
        { title: 'Testează strategii', content: 'Testează diverse combinații de cărți pentru a-ți optimiza strategia de joc'},
        { title: 'MTG Arena', content: 'Joacă fizic cu orice carte eliberată doar pentru Magic The Gathering Arena Online'},
        { title: 'Spate personalizat', content: 'Distrează-te cu prietenii tăi folosind cărți cu spatele personalizat'},
        { title: 'Protejare carduri', content: 'Cardurile proxy MTG iți permit să joci cu cele mai scumpe cărți fără a deteriora cardurile originale.'},
        { title: 'Ediții speciale', content: 'Bucură-te de cărți deosebite din ediții si variații greu de găsit'},
        { title: 'Cărți token', content: 'Printează numeroase cărți proxy token pentru deck-uri de tip agro'},
        { title: 'Cărți nepublicate', content: 'Testează cărți nepublicate încă'}
]


    return (
        <div className='with-margins layer-up'>
            <div className="section-title text-lg-2">De ce cărți Proxy</div>
            <div className="dece-row">
                <DeceCard text={items[0]} showFull={showFullDece} />
                <DeceCard text={items[1]} showFull={showFullDece} />
                <DeceCard text={items[2]} showFull={showFullDece} />
                <DeceCard text={items[3]} showFull={showFullDece} />
                <DeceCard text={items[4]} showFull={showFullDece} />
                <DeceCard text={items[5]} showFull={showFullDece} />
                <DeceCard text={items[6]} showFull={showFullDece} />
                <DeceCard text={items[7]} showFull={showFullDece} />
                <DeceCard text={items[8]} showFull={showFullDece} onlyMobile={true}/>
            </div>
        </div>
    )
}
export default Dece;