import { useState } from 'react';
import './CumComand.css'

function CumComand() {
    const [expanded_1, setExpanded1] = useState(false);    
    const [expanded_2, setExpanded2] = useState(false);    
    const [expanded_3, setExpanded3] = useState(false);    

    // îâșță 
    // cărți
    return (
        <div className="layer-up">
            <div className="section-back ">
                <div className='section-front'>
                    <div className="section-title text-lg-2 with-margins">Plasarea comenzii</div>

                    <div className='vertical'>
                        <div className='steps-container'>
                            <ol className="order-steps text-sm-1">
                                <li className='cc-item'>
                                    <div>Completează lista de cărți în secțiunea de mai jos</div>
                                    <div onClick={() => setExpanded1(!expanded_1)} className={ expanded_1 ? 'cc-hide' : 'cc-expand-btn text-sm-2 disable-select' }>Citește mai mult</div>
                                    <div className={expanded_1 ? '' : 'cc-hide'}>
                                        <div className='space-xxs-mobile'></div>
                                        <div className='cc-details text-sm-2'>Exemplul conține diverse opțiuni de prezentare a cărților</div>
                                        <div className='cc-details text-sm-2'>Comanda se trimite prin email la adresa  comenzimtg@comenzi.mtgproxyshop.com. Este important ca subiectul să conțină Numele si Data comenzii</div>
                                        <div className='cc-details text-sm-2'>La apăsarea "Trimite comanda" se va deschide aplicația predefinită de email. Textul afișat este sugestiv și se poate edita</div>
                                        <div className='cc-details text-sm-2'>Ca sursă de informare despre cărți poți folosi <a target="_blank" href=" https://scryfall.com/">Scryfall</a> sau site-uri similare</div>
                                        <div onClick={() => setExpanded1(false)} className={ expanded_1 ? 'cc-expand-btn text-sm-2 disable-select' : 'hide'}>Inchide</div>
                                    </div>
                                </li>

                                <li className='cc-item'>
                                    <div>Primește confirmarea</div>
                                    <div onClick={() => setExpanded2(!expanded_2)} className={ expanded_2 ? 'cc-hide' : 'cc-expand-btn text-sm-2 disable-select' }>Citește mai mult</div>
                                    <div className={expanded_2 ? '' : 'cc-hide'}>
                                        <div className='cc-details text-sm-2'>După efectuarea pasului de mai sus vei primi confirmarea comenzii</div>
                                        <div className='cc-details text-sm-2'>Confirmarea comenzii va avea atașată factura in baza căreia se poate face plata</div>
                                        <div onClick={() => setExpanded2(false)} className={ expanded_2 ? 'cc-expand-btn text-sm-2 disable-select' : 'hide'}>Inchide</div>
                                    </div>
                                </li>

                                <li className='cc-item'>
                                    <div>Plata</div>
                                    <div onClick={() => setExpanded3(!expanded_3)} className={ expanded_3 ? 'cc-hide' : 'cc-expand-btn text-sm-2 disable-select' }>Citește mai mult</div>
                                    <div className={expanded_3 ? '' : 'cc-hide'}>
                                        <div className='cc-details text-sm-2'>Plata se poate face prin transfer bancar sau în numerar la primirea coletului</div>
                                        <div className='cc-details text-sm-2'>IBAN-ul îl găsești pe factura primită odată cu confirmarea comenzii</div>
                                        <div onClick={() => setExpanded3(false)} className={ expanded_3 ? 'cc-expand-btn text-sm-2 disable-select' : 'hide'}>Inchide</div>
                                    </div>
                                </li>

                            </ol>
                        </div>

                        <div className='space-xs'></div>
                        {/* <div className='exemplu-button text-sm-1 disable-select' onClick={() => descarcaExemplu()}>Descara exemplu</div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CumComand;