import { useState } from 'react';
import ExpandingText from '../../expandingText/ExpandingText';
import './DeCeCard.css'

function DeceCard({text, showFull, onlyMobile}) {

    const [cardIsCollapsed, setCardIsCollaped] = useState(true);

    return (
        <div onClick={() => setCardIsCollaped(!cardIsCollapsed) } className={ onlyMobile ? 'show-on-mobile dece-card-container' : 'dece-card-container'} >
            { !showFull && <div className={ cardIsCollapsed ? 'title-collapsed' : 'dece-card-title text-md-2'}>{text.title}</div> }
            { showFull && <div className="dece-card-title text-md-2">{text.title}</div>}
            { showFull && <div className='et-content dece-card-text text-md-2 text-lighter'>{text.content}</div> }
            { !showFull && <ExpandingText text={text} collapsed={cardIsCollapsed} /> }
        </div>
    )
}
export default DeceCard;