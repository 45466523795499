import './TrimiteComanda.css'
import comanda from '../images/comanda.jpg'
import  { useState } from 'react'
import TrimiteComandaModal from './TrimiteComandaModal';


function TrimiteComanda() {
    const prefilledOrder = `2X World Enchantment
Cindervines RVR
Murderer's Axe (SOI) 259
    `
    const [cards, setCards] = useState(prefilledOrder);
    const [nume, setNume] = useState("");
    const [adresa, setAdresa] = useState("");
    const [telefon, setTelefon] = useState("");
    const [error, setError] = useState(false);
    const [wantBox, setWantBox] = useState(true);
    const [numerar, setNumerar] = useState(true);
    const [showTcModal, setShowTcModal] = useState(false);

    async function descarcaExemplu() {
        const fileContent = `Arcane Signet
2X Arcane Signet
Murderer's Axe (SOI) 259
2X Murderer's Axe (SOI) 259
Possessed Aven (TOR)
2X Possessed Aven (TOR)

O carte poate fi descrisa în formatele:
Arcane Signet
Arcane Signet LCC
Arcane Signet (LCC) 299
In exemplele de mai sus "LCC" este editia si "299" reprezinta numarul cartii în editia respectiva

Pentru a defini cantitatea dorita foloseste formatul:
2x Arcane Signet

Alte formate posibil parsate sunt:
2 Murderer's Axe (SOI) 259
2x Murderer's Axe (SOI) 259
2X Murderer's Axe (SOI) 259
Murderer's Axe SOI 259
2 Murderer's Axe SOI 259
2x Murderer's Axe SOI 259
2X Murderer's Axe SOI 259
`;
        
        const localfile = new File([fileContent], 'exemplu.txt', {type: 'text/plain'});
        const blob = new Blob([localfile])

        const objectURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectURL
        link.download = 'exemplu.txt';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    function onTrimiteClick() {
        if (nume.length === 0 || cards.length === 0 || adresa.length === 0 || telefon.length === 0 ) {
            alert('Este necesar sa completati Numele, lista carti si adresa de facturare');
            setError(true);
            return;
        } else {
            setShowTcModal(true);
        }
    }

    function openEmail(){
        setShowTcModal(false);

        let email = "comenzimtg@comenzi.mtgproxyshop.com"
        let data = (new Date()).toISOString().slice(0, 10);
        let subject = `Comanda MTG, ${data}, ${nume}`;

        let body = "Comanda pentru cartile:\n\n"
        body += cards

        if (wantBox) {
            body += '\n\n Doresc cutie pentru depozitare la costul de 11 Ron';
        }

        body += `\n\nAdresa de facturare este: ${adresa}`

        if (numerar) {
            body += `\n\nDoresc sa fac plata cu numerar la primirea coletului.`;
        } else {
            body += `\n\nDoresc sa fac plata prin transfer bancar dupa primirea facturii pe email.`;
        }

        body += `\n\n ${nume}`
        body += `\nTel. ${telefon}`
        body = body.replaceAll('\n', '%0D%0A')

        document.location = "mailto:"+email+"?subject="+subject+"&body="+body;
    }

    const saveNume = event => {
        setNume(event.target.value);
        if (nume.length > 0) {
            setError(false)
        }
    }

    const saveCards = event => {
        setCards(event.target.value);
        if ( cards.length > 0) {
            setError(false)
        }
    }

    const saveAdresa = event => {
        setAdresa(event.target.value);
        if ( adresa.length > 0 ) {
            setError(false);
        }
    }

    const saveTelefon = event => {
        setTelefon(event.target.value);
        if ( telefon.length > 8 ) {
            setError(false);
        }
    }

    // îâșță 
    // cărți
    return (
        <div className='tc-container layer-up'>
            <img src={comanda} className="tc-image" alt="Imagine trimite comanda"/>
            <div className='tc-overlay'>
                <div className='tc-send-form'>
                    
                    {/* Numele */}
                    <input className={ error ? 'tc-nume error' : 'tc-nume'} placeholder='Nume Prenume' onChange={saveNume}/>
                    
                    {/* Lista carti */}

                    <div className={ error ? 'tc-around-card-list error' : 'tc-around-card-list'}>
                        <textarea className='tc-cards-list' placeholder='5 Card name (EDT) 223' spellCheck="false" onChange={saveCards} value={cards}></textarea>
                        <div className='tc-exemplu-c' onClick={() => descarcaExemplu()}>Exemplu comandă</div>
                    </div>

                    {/* Daca vrea cutie */}
                    <div id="tc-want-box-dv" className={ wantBox ? 'tc-want-box show' : 'tc-want-box'}>
                        <svg onClick={() => setWantBox(false)} className={ wantBox ? 'tc-want-box-checked' : 'tc-want-box-checked hide'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path fill="#DFDFDF" d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/>
                        </svg>

                        <svg onClick={() => setWantBox(true)} className={ !wantBox ? 'tc-want-box-checked' : 'tc-want-box-checked hide'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path fill="#DFDFDF" d="M384 80c8.8 0 16 7.2 16 16V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V96c0-8.8 7.2-16 16-16H384zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"/>
                        </svg>

                        <div onClick={() => setWantBox(!wantBox)} className='want-box-ck disable-select'>Doresc cutie (+ 11 RON)</div>
                    </div>

                    {/* Adresa facturare */}
                    <input className={ error ? 'tc-address error' : 'tc-address'} placeholder='Adresă facturare' onChange={saveAdresa}/>

                    {/* Telefon */}
                    <input className={ error ? 'tc-address error' : 'tc-address'} placeholder='Telefon' onChange={saveTelefon}/>

                    {/* Selectie forma plata */}
                    <div className='tc-plata-div text-sm-1'>
                        <div className='plata-label'>Doresc să fac plata</div>
                        <div className='plata-option text-sm-2 disable-select'>
                            <svg className={ !numerar ? 'option-icon': 'option-icon hide'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#fff"  d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"/></svg>
                            <svg onClick={() => setNumerar(false)} className={ numerar ? 'option-icon': 'option-icon hide'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#fff" d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>
                            <div className='option-label' onClick={() => setNumerar(false)}>Prin transfer bancar</div>
                        </div>

                        <div className='plata-option text-sm-2 disable-select'>
                            <svg className={ numerar ? 'option-icon': 'option-icon hide'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#fff"  d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"/></svg>
                            <svg onClick={() => setNumerar(true)} className={ !numerar ? 'option-icon': 'option-icon hide'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#fff" d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>
                            <div className='option-label' onClick={() => setNumerar(true)}>Numerar la primirea coletului</div>
                        </div>
                    </div>

                    <div className='tc-button text-sm-1 disable-select' onClick={() => onTrimiteClick() }>Trimite comanda</div>
                    <div className='tc-space-below'></div>
                    <div className={ showTcModal ? 'no' : 'hide'}>
                        <TrimiteComandaModal okFc={openEmail} cancelFc={ () => { setShowTcModal(false); setShowTcModal(false)} } />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TrimiteComanda;