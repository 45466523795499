import { useEffect, useState } from 'react';
import './ExpandingText.css'

function ExpandingText({text, collapsed}) {
  const [displayedContent, setDisplayedContent] = useState(text.content.substr(0, 20) + '...');

  function addNextLetter() {
    if (displayedContent !== text.content && !collapsed) {
      const nextLetter = text.content.substr(displayedContent.length, 1);
      setDisplayedContent(displayedContent + nextLetter);
    }
  }
  
  useEffect(() => {
    const timer = setTimeout(() => {
      addNextLetter();
    }, 10);
    return () => clearTimeout(timer);
  // eslint-disable-next-line
  }, [displayedContent]);

  useEffect(() => {
    if (collapsed) {
      setDisplayedContent(text.title);
    } else {
      setDisplayedContent(text.content.substr(0, 10));
    }
  }, [collapsed, text.title, text.content]);

  return (
      <div className={ collapsed ? 'et-container text-collapsed text-md-2' : 'et-container text-not-collapsed' }>
        <div
          className='et-content dece-card-text text-md-2 text-lighter'>{displayedContent}</div>
      </div>
  )
}
export default ExpandingText;