import './Legislatie.css'

function Legislatie() {

    // îâșță 
    // cărți
    return (
        <div className="layer-up">
            <div className="section-back ">
                <div className='section-front'>
                    <div className="section-title text-lg-2 with-margins">Legislație și etică</div>

                    <div className='vertical'>
                        <div className='dc-container'>
                            <div className='space-xs'></div>

                            <div className="order-steps text-sm-1">
                                <div className='cc-item'>
                                    <div>Din punct de vedere legal, Wizards of the Coast nu permite utilizarea proxy-urilor în turnee oficiale sau evenimente aprobate. Cu toate acestea, ei recunosc natura ocazională a utilizării proxy-ului în medii necompetitive, informale. Este important să vă asigurați că utilizarea proxy-urilor este convenită de toți participanții într-un cadru de joc casual</div>
                                </div>

                                <div className='space-xs'></div>

                                <div className='cc-item'>
                                    <div>Utilizarea etică a proxy-urilor MTG în mod casual este în general acceptată, în special pentru testarea deck-urilor sau pentru a vă bucura de joc fără efort financiar. Considerentele cheie includ comunicarea transparentă cu grupul dvs. de joc, utilizarea proxy-urilor pentru a echilibra terenul de joc, mai degrabă decât pentru a obține un avantaj nedrept și respectarea economiei jocului prin neînlocuirea colecției autentice de cărți cu proxy-uri</div>
                                </div>
                            </div>
    
                            <div className='space-xs'></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Legislatie;