import './Preturi.css'
import Pret from './pret/Pret';
import imgPret1 from '../images/p1.jpg'
import imgPret2 from '../images/p2.jpg'
import imgPret3 from '../images/p3.jpg'
import imgPret4 from '../images/p4.jpg'

function Preturi() {
    // ă ț î â ș
    // cărți
    let preturi = [
        {image: imgPret1, titlu: "1 - 10 cărți", pret: "7 Lei", details: "" },
        {image: imgPret2, titlu: "11 - 50 cărți", pret: "5 Lei", details: "" },
        {image: imgPret3, titlu: "51 - 130 cărți", pret: "4 Lei", details: "+ cutie" },
        {image: imgPret4, titlu: "Peste 130 cărți", pret: "3 Lei", details: "+ cutie și 5 carduri" }
    ]

    return (
        <div>
            <div className="section-back layer-up">
                <div className='section-front'>
                    <div className="section-title text-lg-2 with-margins">Prețuri per carte</div>
                    <div className='preturi-container'>
                        {preturi.map( (pret, index) => (
                            <Pret key={index} image={pret.image} titlu={pret.titlu} pret={pret.pret} details={pret.details}/>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Preturi;