import './App.css';
import './home/Home'
import Home from './home/Home';

function App() {
  return (
    <div className="App">
      <Home/>
    </div>
  );
}

export default App;
