import './Pret.css'

function Pret({image, titlu, details, pret}) {
    return (
        <div className='pret-container'>
            <img className="pret-image" src={image} alt="Imagine pret" />
            <div className="image-overlay" />

            <div className='pret-label text-sm-1'>{titlu}</div>
            <div className='pret-value'>
                <div className='text-lg-1'>{pret}</div>
                <div className='pret-details text-sm-2'>{details}</div>
            </div>
        </div>
    )
}
export default Pret;