import { useEffect, useState } from "react";
import Dece from "../dece/Dece";
import Header from "../header/Header"
import CoverImage from "./cover-image/CoverImage";
import Servicii from "../servicii/Servicii";
import './Home.css'
import Preturi from "../preturi/Preturi";
import CumComand from "../cumComand/CumCommand";
import TrimiteComanda from "../trimiteComanda/TrimiteComanda";
import Footer from "../footer/Footer";
import DefinireCarte from "../definireCarte/DefinireCarte";
import Legislatie from "../legislatie/Legislatie";

function Home() {
    const [overlayBackground, setOverlayBackground] = useState('rgba(0,0,0,0)')

    const handleScroll = () => {
        // Vreau sa setez inaltimea pana la care creste dark overlay pana la valoarea maxima
        // Difera functie de tipul de device pentru ca fiecare device are Y ecran diferita
        const wh = window.innerHeight;
        const rangeMax = 0.8 * wh;
        const panta = 0.75 / rangeMax;
        if ( window.scrollY > rangeMax) {
            setOverlayBackground('rgba(0,0,0,0.75)');
        }
        else {
            const opacity = window.scrollY * panta;
            setOverlayBackground(`rgba(0,0,0,${opacity})`);
        }
    }

    useEffect( () => {
        window.addEventListener('scroll', handleScroll, {passive: true});

        return () => {
            window.removeEventListener('scroll', handleScroll); 
        }
    }, [overlayBackground]);

    const sendEvent = () => {
        if(window.location.hostname === 'localhost' || window.location.hostname.indexOf('192.168.0') === 0) {
            return;
        }

        if(!sessionStorage.getItem('visit')) {
            sessionStorage.setItem('visit', true);

            fetch('https://api.ipify.org?format=json')
                .then((ipResp) => ipResp.json().then((ipJson) => {
                    fetch('https://09mclyfv7k.execute-api.eu-central-1.amazonaws.com/prod/log_event', {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({type: 'visit', name: 'mtg-proxy', from: ipJson}),
                    }).then((response) => response.json().then((responseData) => {
                        sessionStorage.setItem('success', responseData.EventId);
                    }));
                })
            );
        }
    }

    return (
        <div id="top-scroll-over" onLoad={() => sendEvent()}>
            <Header />
            <div>
                <div className="cover-image-overlay" style={{'backgroundColor': overlayBackground }} />
                <CoverImage />
                <Dece/>
                <Servicii />
                <Preturi />
                <CumComand />
                <TrimiteComanda />
                <DefinireCarte />
                <div className='dark-blue-line' />
                <Legislatie />
                <div className='dark-blue-line' />
                <Footer />
            </div>
        </div>
    )
}

export default Home;