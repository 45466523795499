import './Footer.css'
import logo from '../images/logo.png'


function Footer() {
    return (
        <div className='footer-container'>
            <div className='logo-wrapper'>
                <img className="logo" src={logo} alt="Logo" />
            </div>
            <div className='ft-column'>
                {/* <div className='ft-item'>Adresa<span className='ft-value'>Str Rezervelor 62, Bl 3, Roșu, Jud Ilfov</span></div> */}
                <div className='ft-item'>Copyright<span className='ft-value'>MTG ProxyShop</span></div>
            </div>

            <div className='ft-column'>
                <div className='ft-item'>Contact<span className='ft-value'>principal@comenzi.mtgproxyshop.com</span></div>
                <div className='ft-item'>Version<span className='ft-value'>v4.4</span></div>
            </div>
        </div>
    )
}
export default Footer;