import './CoverImage.css'

function CoverImage() {
    return (
        <div className="cover-image-container with-margins">
            <div id="main-title" className="cover-image-item text-lg">MTG Proxy Shop</div>
            <div className="cover-image-item text-md-1">Bine ai venit la </div>
            <div id="cover-image-space"></div>
        </div>
    )
}
export default CoverImage;