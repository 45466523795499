import './TrimiteComandaModal.css'

function TrimiteComandaModal({okFc, cancelFc}) {

    // îâșță
    // cărți
    return (
        <div className='tc-modal-container'>
            <div className='tc-modal-back-content'>
                <div className='tc-modal-content text-sm-2'>
                    <div>Comanda se trimite prin email la adresa comenzimtg@comenzi.mtgproxyshop.com. Este important ca subiectul să conțină numele, prenumele si data comenzii. La apăsarea butonului "Trimite" se va deschide aplicația predefinită de email.</div>
                    <div>Dacă adresa de livrare diferă de cea de facturare, adaugă adresa de livrare in textul email-ului.</div>
                    <div>După trimiterea comenzii vei primi o confirmare care va avea atașată factura in baza căreia se poate face plata. </div>
                    <div>Plata se poate face, conform selectiei tale, prin transfer bancar sau în numerar la primirea coletului. IBAN-ul îl găsești pe factura primită odată cu confirmarea comenzii.</div>
                    <div className='tc-modal-buttons text-md-1'>
                        <div className='tc-modal-button' onClick={ () => cancelFc() }>Inchide</div>
                        <div className='tc-modal-button' onClick={ () => okFc() }>Trimite</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TrimiteComandaModal;