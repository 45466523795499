import './DefinireCarte.css'

function DefinireCarte() {
    async function descarcaExemplu() {
        const fileContent = `Arcane Signet
2X Arcane Signet
Murderer's Axe (SOI) 259
2X Murderer's Axe (SOI) 259
Possessed Aven (TOR)
2X Possessed Aven (TOR)

O carte poate fi descrisa în formatele:
Arcane Signet
Arcane Signet LCC
Arcane Signet (LCC) 299
In exemplele de mai sus "LCC" este editia si "299" reprezinta numarul cartii in editia respectiva

Pentru a defini cantitatea dorita foloseste formatul:
2x Arcane Signet

Alte formate posibil parsate sunt:
2 Murderer's Axe (SOI) 259
2x Murderer's Axe (SOI) 259
2X Murderer's Axe (SOI) 259
Murderer's Axe SOI 259
2 Murderer's Axe SOI 259
2x Murderer's Axe SOI 259
2X Murderer's Axe SOI 259
`;
        
        const localfile = new File([fileContent], 'exemplu.txt', {type: 'text/plain'});
        const blob = new Blob([localfile])

        const objectURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = objectURL
        link.download = 'exemplu.txt';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    // Îîâșță
    // cărți
    return (
        <div className="layer-up">
            <div className="section-back ">
                <div className='section-front'>
                    <div className="section-title text-lg-2 with-margins">Definiție carte</div>

                    <div className='vertical'>
                        <div className='dc-container'>
                            <div className="order-steps text-sm-1">
                                <div className='cc-item'>
                                    <div>O carte poate fi descrisă în formatele</div>
                                    <div className='cc-details text-sm-2'>Arcane Signet</div>
                                    <div className='cc-details text-sm-2'>Arcane Signet LCC</div>
                                    <div className='cc-details text-sm-2'>Arcane Signet (LCC) 299</div>
                                    <div className='cc-details text-sm-2'>În exemplele de mai sus "LCC" este ediția și "299" reprezintă numărul cărții în ediția respectivă</div>
                                </div>

                                <div className='cc-item'>
                                    <div>Pentru a defini cantitatea dorită folosește formatul</div>
                                    <div className='cc-details text-sm-2'>2x Arcane Signet</div>
                                </div>
                            </div>
                        </div>

                        <div className='df-exemplu-button text-sm-1' onClick={() => descarcaExemplu()}>Descarcă exemplul</div>
                        <div className='df-space-below'></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DefinireCarte;